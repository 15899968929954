import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CalendarResponse, SeasonData } from '@modules/booking/calendar.service';
import { CatalogService } from '@services/catalog.service';
import { LocalSettingsService } from '@services/settings.service';
import { TranslationsService } from '@services/translations.service';
import { CommonUtils } from '@utils/common.utils';
import {
  CalendarCommonModule,
  CalendarDateFormatter,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthModule,
  CalendarMonthViewDay,
} from 'angular-calendar';
import { CalendarEvent, DAYS_OF_WEEK } from 'calendar-utils';
import { addMonths, startOfDay, subMonths } from 'date-fns';
import { cloneDeep, find, isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import { Observable, Subject, first, map } from 'rxjs';
import { CustomDateFormatter } from './custom-date.service';

const DEFAULT_SEASON = 'HIGH_SEASON';

@Component({
  selector: 'cmp-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  standalone: true,
  imports: [NgClass, NgIf, CalendarMonthModule, AsyncPipe, CalendarCommonModule],
})
export class CalendarComponent implements OnInit, OnChanges {
  /**
   * Data to set enabled and disabled dates
   */
  @Input() calendarData: CalendarResponse[] = [];
  /**
   * Data to set enabled and disabled dates
   */
  @Input() plan: string = '';
  /**
   * Data to set seasons prices
   */
  @Input() seasonData: SeasonData;
  /**
   * Selected date event
   */
  @Output() selectedDay: EventEmitter<string> = new EventEmitter();
  selectedMonthViewDay: CalendarMonthViewDay;
  locale: string = 'es-ES';
  lang: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  viewDate: Date = new Date();
  nextMonthViewDate: Date = addMonths(new Date(), 1);
  next2MonthViewDate: Date = addMonths(new Date(), 2);
  next3MonthViewDate: Date = addMonths(new Date(), 3);
  next4MonthViewDate: Date = addMonths(new Date(), 4);
  nextMonthTrnsl: string = 'Siguiente'; // TODO TO TRANSLATE
  prevMonthTrnsl: string = 'Anterior'; // TODO TO TRANSLATE
  todayTrnsl: string = 'Hoy'; // TODO TO TRANSLATE
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<void> = new Subject();
  actions: CalendarEventAction[] = [];
  events: CalendarEvent[] = [];

  minDate: Date = subMonths(new Date(), 1); // SET MIN PERIOD DATE
  maxDate: Date = addMonths(new Date(), 1); // SET MAX PERIOD DATE
  takenDates: Date[] = [];
  freeDates: Date[] = [];
  translations: [];
  isMobile$: Observable<boolean>;
  responseSeason: [];
  labels: any = {};

  constructor(
    private settingsService: LocalSettingsService,
    private translationsService: TranslationsService,
    private catalogService: CatalogService,
    private responsive: BreakpointObserver
  ) {}

  ngOnInit() {
    this.isMobile$ = this.responsive.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(map(res => res.matches));

    this.translationsService.getTranslations({ pageName: 'sagardobus_common' }).subscribe(translations => {
      this.translations = translations;
      this.setTranslations(this.settingsService.getLanguage());
    });
    this.settingsService.currentLanguage.subscribe((lang: string) => {
      this.setTranslations(lang);
      this.refresh.next();
    });

    this.catalogService
      .getCatalog({ type: 'SEASON' })
      .pipe(first())
      .subscribe(res => {
        this.responseSeason = res;
        this.setSeasonTranslation(this.locale);
      });

    this.getTakenDays();
    this.dateOrViewChanged();
  }

  ngOnChanges(changes) {
    if (changes['calendarData']) {
      this.calendarData = changes ? cloneDeep(changes['calendarData'].currentValue) : cloneDeep(this.calendarData);
    }
    if (changes['plan']) {
      this.setSeasonTranslation(this.locale);
    }
    this.getTakenDays();
  }

  previousMonth() {
    this.viewDate = subMonths(this.viewDate, 1);
    this.nextMonthViewDate = subMonths(this.nextMonthViewDate, 1);
    this.next2MonthViewDate = subMonths(this.next2MonthViewDate, 1);
    this.next3MonthViewDate = subMonths(this.next3MonthViewDate, 1);
    this.next4MonthViewDate = subMonths(this.next4MonthViewDate, 1);
  }

  onSwipeLeft(event, data) {
    this.nextMonth();
  }

  onSwipeRight(event, data) {
    this.previousMonth();
  }

  nextMonth() {
    this.viewDate = addMonths(this.viewDate, 1);
    this.nextMonthViewDate = addMonths(this.nextMonthViewDate, 1);
    this.next2MonthViewDate = addMonths(this.next2MonthViewDate, 1);
    this.next3MonthViewDate = addMonths(this.next3MonthViewDate, 1);
    this.next4MonthViewDate = addMonths(this.next4MonthViewDate, 1);
  }

  setTranslations(lang) {
    this.lang = lang;
    this.nextMonthTrnsl = CommonUtils.getTranslation(this.translations, 'sagardobus_common_next', lang);
    this.prevMonthTrnsl = CommonUtils.getTranslation(this.translations, 'sagardobus_common_previous', lang);
    this.todayTrnsl = CommonUtils.getTranslation(this.translations, 'sagardobus_common_today', lang);
    this.labels['complete'] = CommonUtils.getTranslation(this.translations, 'sagardobus_common_complete', lang);
    this.labels['from'] = CommonUtils.getTranslation(this.translations, 'sagardobus_common_from', lang);
    this.setSeasonTranslation(lang);
    this.refresh.next();
  }

  setSeasonTranslation(lang) {
    if (!isUndefined(this.seasonData) && !isEmpty(this.labels)) {
      if (lang !== 'eu') {
        this.labels['seasonLow'] =
          this.labels['from'].charAt(0).toUpperCase() +
          this.labels['from'].slice(1) +
          ' ' +
          this.seasonData[this.plan]['LOW_SEASON'] +
          '€';
      } else {
        this.labels['seasonLow'] = this.seasonData[this.plan]['LOW_SEASON'] + '€-' + this.labels['from'];
      }

      if (lang !== 'eu') {
        this.labels['seasonMedium'] =
          this.labels['from'].charAt(0).toUpperCase() +
          this.labels['from'].slice(1) +
          ' ' +
          this.seasonData[this.plan]['MID_SEASON'] +
          '€';
      } else {
        this.labels['seasonMedium'] = this.seasonData[this.plan]['MID_SEASON'] + '€-' + this.labels['from'];
      }

      if (lang !== 'eu') {
        this.labels['seasonHigh'] =
          this.labels['from'].charAt(0).toUpperCase() +
          this.labels['from'].slice(1) +
          ' ' +
          this.seasonData[this.plan]['HIGH_SEASON'] +
          '€';
      } else {
        this.labels['seasonHigh'] = this.seasonData[this.plan]['HIGH_SEASON'] + '€-' + this.labels['from'];
      }

      this.locale = lang === 'es' ? 'es-ES' : lang;
    }
  }

  dayClicked(day: CalendarMonthViewDay): void {
    if (this.isSameMonthUTC(day.date, this.viewDate)) {
      if (this.isSameDayUTC(this.viewDate, day.date) || day.events.length === 0) {
      } else {
        this.viewDate = day.date;
      }
    }
    if (this.selectedMonthViewDay) {
      delete this.selectedMonthViewDay.cssClass;
    }
    day.cssClass = 'cal-day-selected';
    this.selectedMonthViewDay = day;
    this.selectedDay.emit(day ? this.formatDate(startOfDay(day.date)) : '');
    this.refresh.next();
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (this.selectedMonthViewDay && this.isSameDayUTC(day.date, this.selectedMonthViewDay.date)) {
        day.cssClass = 'cal-free cal-day-selected';
        this.selectedMonthViewDay = day;
      }
      if (!this.dateIsFree(day.date)) {
        day.cssClass = 'cal-disabled';
      } else {
        this.setSeasonClass(day);
      }
      if (this.dateIsTaken(day.date)) {
        day.cssClass = 'cal-taken';
        day.meta = {};
        if (this.lang && this.translations) {
          day.meta.text = CommonUtils.getTranslation(this.translations, 'sagardobus_common_complete', this.lang);
        }
      } else {
        const calendarDate = find(this.calendarData, cDate => {
          return this.isSameDayUTC(moment(cDate.date.split('T')[0]).utc().toDate(), day.date);
        });
        if (!isUndefined(calendarDate)) {
          if (calendarDate.season === 'HIGH_SEASON') {
            day.cssClass = 'cal-high-season';
          } else if (calendarDate.season === 'MID_SEASON') {
            day.cssClass = 'cal-mid-season';
          } else if (calendarDate.season === 'LOW_SEASON') {
            day.cssClass = 'cal-low-season';
          }
        }
      }
    });
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next();
  }

  dateOrViewChanged(): void {
    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  private changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  private setSeasonClass(day: CalendarMonthViewDay) {
    day.meta = {};
    const calendarDate = find(this.calendarData, cDate => {
      return this.isSameDayUTC(moment(cDate.date.split('T')[0]).utc().toDate(), day.date);
    });
    if (!isUndefined(calendarDate)) {
      const from = CommonUtils.getTranslation(this.translations, 'sagardobus_common_from', this.lang);
      if (!isUndefined(calendarDate.min_prices)) {
        if (this.lang === 'eu') {
          day.meta.text = `${calendarDate.min_prices[this.plan]}€-${from}`;
        } else {
          day.meta.text = `${from} ${calendarDate.min_prices[this.plan]}€`;
        }
      } else {
        if (this.lang === 'eu') {
          day.meta.text = `--€-${from}`;
        } else {
          day.meta.text = `${from} --€`;
        }
      }

      day.meta.season = calendarDate.season ? calendarDate.season : DEFAULT_SEASON;
    }
  }

  private dateIsFree(date: Date): boolean {
    return find(this.freeDates, (val: any) => {
      return this.isSameDayUTC(date, val);
    });
  }

  private dateIsTaken(date: Date): boolean {
    return find(this.takenDates, (val: any) => {
      return this.isSameDayUTC(date, val);
    });
  }

  private getTakenDays() {
    this.takenDates = [];
    this.freeDates = [];
    this.calendarData.forEach((item: CalendarResponse) => {
      if (!!item.closed) {
        this.takenDates.push(moment(item.date.split('T')[0]).utc().toDate());
      } else {
        this.freeDates.push(moment(item.date.split('T')[0]).utc().toDate());
      }
    });

    this.refresh.next();
  }

  private normalizeDateUTC(date: Date): moment.Moment {
    return moment.utc(date).startOf('day');
  }

  private isSameDayUTC(date1: Date, date2: Date): boolean {
    return startOfDay(date1).getTime() === startOfDay(date2).getTime();
  }

  private isSameMonthUTC(date1: Date, date2: Date): boolean {
    return this.normalizeDateUTC(date1).isSame(this.normalizeDateUTC(date2), 'month');
  }

  private formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
